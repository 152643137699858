<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAddNews" persistent max-width="650px">
      <v-card>
        <v-toolbar color="indigo" dense>
          <span class="headline white--text">Tambah Informasi Pendukung</span>
          <v-spacer></v-spacer>
          <v-btn dark icon @click="close"> <v-icon>mdi-close</v-icon> </v-btn>
        </v-toolbar>
        <div class="px-3">
          <v-row v-if="tipeFase == 'INDIVIDUKELOMPOK'">
            <v-col cols="12" md="3">Fase</v-col>
            <v-col cols="12" md="9">
              <v-select
                :items="selectFase"
                v-model="fase"
                item-text="state"
                item-value="abbr"
                solo
                hide-details="auto"
                required
                label="pilih tipe.."
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <label for="">Judul Berita</label>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                solo
                label="isi judul berita"
                v-model="judul_berita"
                :error-messages="newsErr"
                @input="$v.judul_berita.$touch()"
                @blur="$v.judul_berita.$touch()"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="3">Isi Berita</v-col>
            <v-col cols="12" md="9">
              <v-select
                :items="selectType"
                v-model="tipe"
                item-text="state"
                item-value="abbr"
                solo
                hide-details="auto"
                required
                label="pilih tipe.."
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="tipe == 'txt'">
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="9">
              <vue-editor
                placeholder="Isi berita.."
                v-model="isi_berita"
                :editorToolbar="customToolbar"
              ></vue-editor>
            </v-col>
          </v-row>

          <v-row v-if="tipe == 'link'">
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="9">
              <v-text-field
                solo
                v-model="link_berita"
                label="Link berita"
                hide-details=""
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-btn color="indigo" dark @click="createNews"
            ><v-icon>mdi-check</v-icon>simpan</v-btn
          >
        </v-card-actions>
      </v-card>
      <DialogLoading v-bind:loading="loader" />
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import DialogLoading from "../../_base/dialogLoading";
import {
  DEL_NEWS,
  UPDATE_BERITA,
  CREATE_BERITA
} from "../../../graphql/Module";
import { VueEditor } from "vue2-editor";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "addNews",

  components: {
    DialogLoading,
    VueEditor
  },

  mixins: [validationMixin],
  validations: {
    judul_berita: { required }
  },
  computed: {
    module() {
      return parseInt(localStorage.getItem("id"));
    },
    newsErr() {
      const errors = [];
      if (!this.$v.judul_berita.$dirty) return errors;
      !this.$v.judul_berita.required &&
        errors.push("judul berita tidak boleh kosong!");
      return errors;
    }
  },
  props: ["dialogAddNews", "tipeFase", "route"],
  data() {
    return {
      judul_berita: "",
      ringkasan_berita: "",
      isi_berita: "",
      link_berita: "",
      beritaByModulId: null,
      loader: false,
      tipe: "link",
      fase: this.tipeFase,
      selectType: [
        { state: "Link", abbr: "link" },
        { state: "Teks", abbr: "txt" }
      ],
      selectFase: [
        { state: "Individu", abbr: "INDIVIDU" },
        { state: "Kelompok", abbr: "KELOMPOK" }
      ],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ]
    };
  },
  methods: {
    createNews() {
      console.log(this.route);
      this.$v.judul_berita.$touch();
      if (!this.$v.judul_berita.required) {
        return;
      } else {
        this.loader = true;
        const modul_id = parseInt(this.route);
        const judul_berita = this.judul_berita;
        const ringkasan_berita = this.ringkasan_berita;
        const isi_berita = this.isi_berita;
        const link = this.link_berita;
        this.$apollo
          .mutate({
            mutation: CREATE_BERITA,
            variables: {
              modul_id: modul_id,
              judul: judul_berita,
              ringkasan_berita: ringkasan_berita,
              isi_berita: isi_berita,
              link: link
            }
          })
          .then(data => {
            this.judul_berita = "";
            this.ringkasan_berita = "";
            this.isi_berita = "";
            this.link_berita = "";
            this.loader = false;
            this.$emit("false");
            console.log(data);
            Swal.fire({
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              position: "top",
              toast: true
            });
          })
          .catch(err => {
            console.log(err.graphQLErrors);
            console.log(err);
            this.loader = false;
            console.log("hai kamu");
          });
      }
    },
    updateNews(item) {
      this.loader = true;
      const id = parseInt(item.id);
      const modul_id = this.modul_id;
      const judul = this.selectedItem.judul;
      const link = this.selectedItem.link;
      const isi_berita = this.selectedItem.isi_berita;
      this.$apollo
        .mutate({
          mutation: UPDATE_BERITA,
          variables: {
            id: id,
            modul_id: modul_id,
            judul: judul,
            link: link,
            isi_berita: isi_berita
          }
        })
        .then(() => {
          this.loader = false;
          this.$emit("false");
          this.$emit("refetch");
          Swal.fire({
            icon: "success",
            title: "Berita telah terupdate",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    delNews(item) {
      Swal.fire({
        title: "Yakin hapus Informasi pendukung?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          const id = parseInt(item.id);
          this.loader = true;
          this.$apollo
            .mutate({
              mutation: DEL_NEWS,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.loader = false;
              this.$emit("refetch");
              this.$emit("false");
              Swal.fire({
                icon: "success",
                title: "Berita telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },
    close() {
      this.$emit("false");
      this.files, (this.chart = null);
      this.kasus = "";
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
