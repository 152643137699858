<template>
  <v-row justify="start">
    <v-dialog v-model="dialogObj" persistent max-width="650px">
      <v-card>
        <v-toolbar color="indigo" dense>
          <span class="headline white--text">Tugas</span>
          <v-spacer></v-spacer>
          <v-btn dark icon @click="close"> <v-icon>mdi-close</v-icon> </v-btn>
        </v-toolbar>
        <div v-if="selectedObj" class="px-3">
          <v-row>
            <v-col cols="12" md="3"><h4>judul</h4></v-col>
            <v-col cols="12" md="9" v-if="!edt">
              <h4>{{ selectedObj.judul }}</h4>
            </v-col>
            <v-col cols="12" md="9" v-else>
              <h4>
                <v-text-field
                  label="judul"
                  solo
                  required
                  hide-details
                  v-model="selectedObj.judul"
                ></v-text-field>
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3"><h4>Keterangan tugas</h4></v-col>
            <v-col cols="12" md="9" v-if="!edt">
              <p v-html="selectedObj.keterangan"></p>
            </v-col>
            <v-col cols="12" md="9" v-else>
              <vue-editor
                v-model="selectedObj.keterangan"
                :editorToolbar="customToolbar"
              ></vue-editor>
            </v-col>
          </v-row>
          <v-row v-if="edt">
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="9">
              <v-btn color="indigo" dark @click="updateObj(selectedObj)"
                >simpan</v-btn
              >
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn color="red" text @click="delObj(selectedObj)"
              ><v-icon>mdi-delete</v-icon> hapus</v-btn
            >
            <v-btn color="cyan" v-if="!edt" text @click="edt = true"
              ><v-icon>mdi-pencil</v-icon>Edit</v-btn
            >
            <v-btn color="cyan" v-if="edt" text @click="edt = false"
              ><v-icon>mdi-close</v-icon>cancel</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
      <DialogLoading v-bind:loading="loader" />
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import DialogLoading from "../../_base/dialogLoading";
import { DEL_OBJECTIVE, UPDATE_TUGAS } from "../../../graphql/Module";
import { VueEditor } from "vue2-editor";

export default {
  name: "viewObj",

  components: {
    DialogLoading,
    VueEditor
  },

  computed: {},
  props: ["dialogObj", "selectedObj", "modul_id", "tipeFase"],
  data: () => ({
    loader: false,
    edt: false,
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      [{ color: [] }]
    ]
  }),
  methods: {
    updateObj(item) {
      console.log(item.fase);
      this.loader = true;
      const id = parseInt(item.id);
      const modul_id = this.modul_id;
      const modul_kasus_id = this.modul_id;
      const judul = item.judul;
      const fase = this.tipeFase;
      const keterangan = this.selectedObj.keterangan;
      this.$apollo
        .mutate({
          mutation: UPDATE_TUGAS,
          variables: {
            id: id,
            modul_id: modul_id,
            modul_kasus_id: modul_kasus_id,
            judul: judul,
            fase: fase,
            keterangan: keterangan
          }
        })
        .then(() => {
          this.loader = false;
          this.$emit("false");
          this.$emit("refetch");
          this.edt = false;
          Swal.fire({
            icon: "success",
            title: "Tugas telah terupdate",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          console.log(err.graphQLErrors);
          console.log(err);
          this.loader = false;
          console.log(err);
        });
    },
    delObj(item) {
      Swal.fire({
        title: "Yakin hapus Tugas?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          const id = parseInt(item.id);
          this.loader = true;
          this.$apollo
            .mutate({
              mutation: DEL_OBJECTIVE,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.loader = false;
              this.$emit("refetch");
              this.$emit("false");
              Swal.fire({
                icon: "success",
                title: "Tugas telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },
    close() {
      this.$emit("false");
      this.files, (this.chart = null);
      this.kasus = "";
      this.edt = false;
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
