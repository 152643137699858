<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAddObj" persistent max-width="650px">
      <v-card>
        <v-toolbar color="indigo" dense>
          <span class="headline white--text">Tambah Tugas</span>
          <v-spacer></v-spacer>
          <v-btn dark icon @click="close"> <v-icon>mdi-close</v-icon> </v-btn>
        </v-toolbar>
        <div class="px-3">
          <v-row v-if="tipeFase == 'INDIVIDUKELOMPOK'">
            <v-col cols="12" md="3">Fase</v-col>
            <v-col cols="12" md="9">
              <v-select
                :items="selectFase"
                v-model="fase"
                item-text="state"
                item-value="abbr"
                solo
                hide-details="auto"
                required
                label="pilih tipe.."
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <label for="">Judul Tugas</label>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                solo
                label="isi judul tugas"
                v-model="judul_tugas"
                :error-messages="objsErr"
                @input="$v.judul_tugas.$touch()"
                @blur="$v.judul_tugas.$touch()"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="3">Keterangan</v-col>
            <v-col cols="12" md="9">
              <vue-editor
                placeholder="keterangan.."
                v-model="keterangan"
                :editorToolbar="customToolbar"
              ></vue-editor>
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-btn color="indigo" dark @click="createTugas"
            ><v-icon>mdi-check</v-icon>simpan</v-btn
          >
        </v-card-actions>
      </v-card>
      <DialogLoading v-bind:loading="loader" />
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import DialogLoading from "../../_base/dialogLoading";
import { DEL_NEWS, CREATE_OBJECTIVE } from "../../../graphql/Module";
import { VueEditor } from "vue2-editor";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "addObj",

  components: {
    DialogLoading,
    VueEditor
  },

  mixins: [validationMixin],
  validations: {
    judul_tugas: { required }
  },
  computed: {
    module() {
      return parseInt(this.modul_id);
    },
    objsErr() {
      const errors = [];
      if (!this.$v.judul_tugas.$dirty) return errors;
      !this.$v.judul_tugas.required &&
        errors.push("Judul tugas tidak boleh kosong!");
      return errors;
    }
  },
  props: ["dialogAddObj", "tipeFase", "route"],
  data() {
    return {
      judul_tugas: "",
      keterangan: "",
      beritaByModulId: null,
      loader: false,
      fase: this.tipeFase,
      selectFase: [
        { state: "Individu", abbr: "INDIVIDU" },
        { state: "Kelompok", abbr: "KELOMPOK" }
      ],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ]
    };
  },
  methods: {
    createTugas() {
      this.$v.judul_tugas.$touch();
      if (!this.$v.judul_tugas.required) {
        return;
      } else {
        this.loader = true;
        const modul_id = parseInt(this.route);
        const modul_kasus_id = null;
        const judul_tugas = this.judul_tugas;
        const keterangan = this.keterangan;
        const fase = this.fase;
        this.$apollo
          .mutate({
            mutation: CREATE_OBJECTIVE,
            variables: {
              modul_id: modul_id,
              modul_kasus_id: modul_kasus_id,
              judul: judul_tugas,
              fase: fase,
              keterangan: keterangan
            }
          })
          .then(data => {
            this.judul_tugas = "";
            this.keterangan = "";
            this.loader = false;
            this.$emit("false");
            console.log(data);
            Swal.fire({
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              position: "top",
              toast: true
            });
          })
          .catch(err => {
            console.log(err.graphQLErrors);
            console.log(err);
            this.loader = false;
            console.log("hai kamu");
          });
      }
    },
    delNews(item) {
      Swal.fire({
        title: "Yakin hapus Informasi pendukung?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          const id = parseInt(item.id);
          this.loader = true;
          this.$apollo
            .mutate({
              mutation: DEL_NEWS,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.loader = false;
              this.$emit("refetch");
              this.$emit("false");
              Swal.fire({
                icon: "success",
                title: "Berita telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },
    close() {
      this.$emit("false");
      this.judul_tugas = "";
      this.keterangan = "";
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
