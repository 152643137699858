<template>
  <div class="bg-default pa-2">
    <v-progress-linear
      :active="loadModul"
      :indeterminate="loadModul"
      absolute
      top
      color="orange"
    ></v-progress-linear>
    <nav class="pa-3 d-flex">
      <router-link to="/list-module" style="text-decoration: none">
        <v-btn dark fab small color="indigo">
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
      </router-link>
    </nav>
    <section class="card pa-2">
      <div class="d-flex">
        <div class="keys">
          <h3>Nama Modul</h3>
        </div>
        <div class="values">
          <v-alert
            border="right"
            colored-border
            color="indigo"
            elevation="2"
            class="pa-2 ma-1"
          >
            <h3>{{ modulById.nama_modul }}</h3>
          </v-alert>
        </div>
      </div>
      <div class="d-flex">
        <div class="keys">
          <h3>Tipe Modul</h3>
        </div>
        <div class="values">
          <v-alert
            border="right"
            colored-border
            color="indigo"
            elevation="2"
            class="pa-2 ma-1"
          >
            <h3>{{ modulById.tipe }}</h3>
          </v-alert>
        </div>
      </div>
    </section>
    <section class="card pa-2">
      <div
        class="d-flex"
        v-if="
          modulById.tipe === 'INDIVIDU' || modulById.tipe === 'INDIVIDUKELOMPOK'
        "
      >
        <div class="keys">
          <h3>Narasi Individu</h3>
        </div>
        <div class="values" v-if="modulById.narasi_individu !== ''">
          <v-alert
            border="right"
            colored-border
            color="indigo"
            elevation="2"
            class="pa-2 ma-1"
          >
            <div class="d-flex justify-space-between">
              <h3 class="text-justify" v-html="modulById.narasi_individu"></h3>
              <section class="mx-2">
                <v-btn color="cyan" icon @click="testing((fase = 'INDIVIDU'))">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </section>
            </div>
          </v-alert>
        </div>
        <v-btn color="indigo" dark small v-else>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <div
        class="d-flex"
        v-if="
          modulById.tipe === 'KELOMPOK' || modulById.tipe === 'INDIVIDUKELOMPOK'
        "
      >
        <div class="keys">
          <h3>Narasi Kelompok</h3>
        </div>
        <div class="values" v-if="modulById.narasi_kelompok !== ''">
          <v-alert
            border="right"
            colored-border
            color="indigo"
            elevation="2"
            class="pa-2 ma-1"
          >
            <section class="d-flex justify-space-between">
              <h3 class="text-justify" v-html="modulById.narasi_kelompok"></h3>
              <section class="mx-2">
                <v-btn color="cyan" icon @click="testing((fase = 'KELOMPOK'))">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </section>
            </section>
          </v-alert>
        </div>
        <v-btn color="indigo" dark small v-else>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </section>
    <section class="card pa-2">
      <div class="d-flex">
        <div class="keys">
          <h3>Data Kasus</h3>
        </div>
        <div class="values" v-if="modulById.modul_kasus">
          <div v-if="modulById.modul_kasus.length">
            <section v-for="(kasus, idx) in modulById.modul_kasus" :key="idx">
              <v-alert
                border="right"
                colored-border
                color="indigo"
                elevation="2"
                class="pa-2 ma-1"
              >
                <div class="d-flex justify-space-between">
                  <section>
                    <div class="d-flex">
                      <v-icon class="mr-2">mdi-file</v-icon>
                      <h3>{{ kasus.kasus }}</h3>
                    </div>
                    <div class="d-flex">
                      <v-icon class="mr-2">mdi-file-excel</v-icon>
                      <h3>{{ kasus.file }}</h3>
                    </div>
                    <div class="d-flex">
                      <v-icon class="mr-2">mdi-chart-bar</v-icon>
                      <h3>{{ kasus.chart }}</h3>
                    </div>
                    <div class="d-flex">
                      <v-icon class="mr-2">mdi-clock</v-icon>
                      <h5>{{ kasus.fase }}</h5>
                    </div>
                  </section>
                  <section class="pa-2 d-flex flex-column">
                    <v-btn text color="indigo">
                      <v-icon @click="preview(kasus)"> mdi-eye </v-icon>
                    </v-btn>
                    <v-btn text color="red" @click="delCase(kasus)">
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </section>
                </div>
              </v-alert>
            </section>
            <PreviewChart
              v-bind:dataCaseId="caseItem.id"
              v-bind:chartType="caseItem.chart"
              v-bind:dialogChart="dialogChart"
              v-on:false="dialogFalse(2)"
            />
            <v-btn
              color="indigo"
              class="mt-1"
              dark
              small
              @click="dialogCase = true"
            >
              <v-icon>mdi-plus</v-icon></v-btn
            >
          </div>
          <v-btn color="indigo" dark v-else small @click="dialogCase = true">
            <v-icon>mdi-plus</v-icon></v-btn
          >
          <AddCase
            v-bind:dialogCase="dialogCase"
            v-bind:route="id"
            v-bind:type="modulById.tipe"
            v-on:false="dialogFalse(1)"
          />
        </div>
      </div>
    </section>
    <section class="card pa-2">
      <div class="d-flex">
        <div class="keys">
          <h3>Informasi Pendukung</h3>
        </div>
        <div class="values" v-if="modulById.berita">
          <div v-if="modulById.berita.length">
            <section v-for="(news, idx) in modulById.berita" :key="idx">
              <v-alert
                border="right"
                colored-border
                color="indigo"
                elevation="2"
                class="pa-2 ma-1"
              >
                <div class="d-flex justify-space-between">
                  <h3>
                    {{ news.judul }}
                  </h3>
                  <section class="mx-2">
                    <v-btn color="indigo" icon @click="view(news, 'news')"
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </section>
                </div>
              </v-alert>
            </section>
          </div>
          <v-btn color="indigo" dark small @click="dialogAddNews = true">
            <v-icon>mdi-plus</v-icon></v-btn
          >
        </div>
      </div>
    </section>
    <section class="card pa-2">
      <div class="d-flex">
        <div class="keys">
          <h3>Tugas</h3>
        </div>
        <div class="values" v-if="modulById.modul_tugas">
          <div v-if="modulById.modul_tugas.length">
            <section v-for="(tugas, idx) in modulById.modul_tugas" :key="idx">
              <v-alert
                border="right"
                colored-border
                color="indigo"
                elevation="2"
                class="pa-2 ma-1"
              >
                <div class="d-flex justify-space-between">
                  <h3 class="text-justify">
                    {{ tugas.judul }}
                  </h3>
                  <section class="mx-2">
                    <v-btn color="indigo" icon @click="view(tugas, 'obj')"
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </section>
                </div>
                <small class="grey--text"> tugas {{ tugas.fase }} </small>
              </v-alert>
            </section>
          </div>
          <v-btn color="indigo" dark small @click="dialogAddObj = true">
            <v-icon>mdi-plus</v-icon></v-btn
          >
        </div>
      </div>
    </section>
    <EditNarasi
      v-on:false="dialogFalse(7)"
      v-on:refetch="refetchModule()"
      v-bind:dialogNarasi="dialogNarasi"
      v-bind:modul="modulById"
      v-bind:tipeFase="fase"
      v-bind:teacher_id="teacher_id"
    />
    <NewsDetail
      v-on:false="dialogFalse(3)"
      v-on:refetch="refetchModule()"
      v-bind:dialogNews="dialogNews"
      v-bind:modul_id="id"
      v-bind:selectedNews="selectedNews"
    />
    <ObjDetail
      v-if="selectedObj"
      v-on:false="dialogFalse(4)"
      v-on:refetch="refetchModule()"
      v-bind:dialogObj="dialogObj"
      v-bind:modul_id="id"
      v-bind:selectedObj="selectedObj"
      v-bind:tipeFase="tipeFase"
    />
    <AddNews
      v-on:false="dialogFalse(5)"
      v-bind:route="id"
      v-bind:dialogAddNews="dialogAddNews"
      v-bind:tipeFase="tipeFase"
    />
    <AddObj
      v-on:false="dialogFalse(6)"
      v-bind:route="id"
      v-bind:dialogAddObj="dialogAddObj"
      v-bind:tipeFase="tipeFase"
    />
    <DialogLoading v-bind:loading="loading" />
  </div>
</template>

<script>
import { MODULE_BY_ID, DEL_KASUS } from "../../graphql/Module";
import Swal from "sweetalert2";
import EditNarasi from "../../components/Module/Modal/editNarasi";
import AddCase from "../../components/Module/Modal/addDataCase";
import AddNews from "../../components/Module/Modal/addNews";
import AddObj from "../../components/Module/Modal/addObj";
import DialogLoading from "../../components/_base/dialogLoading";
import PreviewChart from "../../components/Teacher/Modal/previewLine";
import NewsDetail from "../../components/Module/Modal/viewNews";
import ObjDetail from "../../components/Module/Modal/viewObj.vue";
export default {
  name: "moduleDetail",
  apollo: {
    modulById: {
      variables() {
        return { id: this.id };
      },
      query: MODULE_BY_ID,
      error(error) {
        console.error(error);
      }
    }
  },
  components: {
    EditNarasi,
    AddCase,
    AddNews,
    AddObj,
    DialogLoading,
    PreviewChart,
    NewsDetail,
    ObjDetail
  },
  computed: {
    id() {
      return parseInt(this.$route.params.idModule);
    },
    tipeFase() {
      let tipe = null;
      if (!this.loadModul) {
        tipe = this.modulById.tipe;
      }
      return tipe;
    },
    loadModul() {
      return this.$apollo.queries.modulById.loading;
    }
  },
  data() {
    return {
      modulById: [],
      dialogNarasi: false,
      dialogCase: false,
      dialogNews: false,
      dialogObj: false,
      dialogChart: false,
      dialogAddNews: false,
      dialogAddObj: false,
      loading: false,
      caseItem: [],
      selectedObj: [],
      fase: null,
      selectedNews: [],
      teacher_id: localStorage.getItem("id")
    };
  },
  methods: {
    refetchModule() {
      this.$apollo.queries.modulById.refetch();
    },

    view(item, type) {
      if (type == "news") {
        console.log(this.modulById.berita);
        this.selectedNews = item;
        this.dialogNews = true;
      } else if (type == "obj") {
        this.selectedObj = item;
        // console.log(item)
        this.dialogObj = true;
      }
    },

    dialogFalse(qna) {
      switch (qna) {
        case 1:
          this.dialogCase = false;
          break;
        case 2:
          this.dialogChart = false;
          break;
        case 3:
          this.dialogNews = false;
          break;
        case 4:
          this.dialogObj = false;
          break;
        case 5:
          this.dialogAddNews = false;
          break;
        case 6:
          this.dialogAddObj = false;
          break;
        case 7:
          this.dialogNarasi = false;
          break;
      }
      this.$store.commit("RESET_CHART");
      this.refetchModule();
    },
    preview(item) {
      this.caseItem = item;
      this.$store
        .dispatch("previewChart", {
          id: item.id,
          chart: item.chart,
          q: null
        })
        .then(() => {
          this.dialogChart = true;
        });
    },
    delCase(item) {
      Swal.fire({
        title: "Yakin hapus data kasus?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.loading = true;
          this.$apollo
            .mutate({
              mutation: DEL_KASUS,
              variables: {
                id: item.id
              }
            })
            .then(() => {
              this.$apollo.queries.modulById.refetch();
              this.loading = false;
              Swal.fire({
                icon: "success",
                title: "Kasus telah terhapus",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            })
            .catch(err => {
              this.loading = false;
              if (err) {
                Swal.fire({
                  icon: "error",
                  title: "Modul sudah di pakai",
                  confirmButtonText: "ok",
                  confirmButtonColor: "#3F51B5",
                  position: "top",
                  toast: true
                });
              }
            });
        }
      });
    },
    testing(e) {
      if (e == "INDIVIDU") {
        this.fase = "INDIVIDU";
        this.dialogNarasi = true;
        return;
      } else {
        this.fase = "KELOMPOK";
        this.dialogNarasi = true;
        return;
      }
    }
  }
};
</script>

<style scoped>
.bg-default {
  background: #f4f6f9;
  width: 100%;
}
.card {
  background: #fff;
  box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin: 25px 5px 25px 5px;
}
.keys {
  width: 30%;
}
.values {
  width: 80%;
}
.card-inside {
  background: #f4f6f9;
  border-radius: 8px;
}
p {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
