<template>
  <v-row justify="start">
    <v-dialog v-model="dialogNarasi" persistent max-width="650px">
      <v-card>
        <v-toolbar color="indigo" dense>
          <span class="headline white--text">Narasi</span>
          <v-spacer></v-spacer>
          <v-btn dark icon @click="close"> <v-icon>mdi-close</v-icon> </v-btn>
        </v-toolbar>
        <div class="px-3">
          <v-row v-if="tipeFase == 'INDIVIDU'">
            <v-col cols="12" md="3"><h4>Narasi Individu</h4></v-col>
            <v-col cols="12" md="9">
              <vue-editor
                placeholder="Narasi"
                v-model="modul.narasi_individu"
                :editorToolbar="customToolbar"
              ></vue-editor>
            </v-col>
          </v-row>
          <v-row v-if="tipeFase == 'KELOMPOK'">
            <v-col cols="12" md="3"><h4>Narasi Kelompok</h4></v-col>
            <v-col cols="12" md="9">
              <vue-editor
                placeholder="Narasi"
                v-model="modul.narasi_kelompok"
                :editorToolbar="customToolbar"
              ></vue-editor>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="9">
              <v-btn color="indigo" dark @click="updateModul(modul)"
                >simpan</v-btn
              >
              <v-btn color="red" text @click="close"
                ><v-icon>mdi-close</v-icon>cancel</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card>
      <DialogLoading v-bind:loading="loader" />
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import DialogLoading from "../../_base/dialogLoading";
import { UPDATE_MODULE } from "../../../graphql/Module";
import { VueEditor } from "vue2-editor";

export default {
  name: "editNarasi",

  components: {
    DialogLoading,
    VueEditor
  },

  computed: {},
  props: ["dialogNarasi", "tipeFase", "modul", "teacher_id"],
  data: () => ({
    loader: false,
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      [{ color: [] }]
    ]
  }),
  methods: {
    updateModul(item) {
      console.log(item);
      this.loader = true;
      const id = parseInt(item.id);
      const teacher_id = this.teacher_id;
      const nama_modul = item.nama_modul;
      const narasi_individu = item.narasi_individu;
      const narasi_kelompok = item.narasi_kelompok;
      this.$apollo
        .mutate({
          mutation: UPDATE_MODULE,
          variables: {
            id: id,
            teacher_id: teacher_id,
            nama_modul: nama_modul,
            narasi_individu: narasi_individu,
            narasi_kelompok: narasi_kelompok
          }
        })
        .then(data => {
          console.log(data);
          this.loader = false;
          this.$emit("false");
          this.$emit("refetch");
          Swal.fire({
            icon: "success",
            title: "Narasi telah terupdate",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    close() {
      this.$emit("false");
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
