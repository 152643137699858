<template>
  <v-row justify="start">
    <v-dialog v-model="dialogNews" persistent max-width="650px">
      <v-card>
        <v-toolbar color="indigo" dense>
          <span class="headline white--text">Informasi Pendukung</span>
          <v-spacer></v-spacer>
          <v-btn dark icon @click="close"> <v-icon>mdi-close</v-icon> </v-btn>
        </v-toolbar>
        <div v-if="selectedNews" class="px-3">
          <v-row>
            <v-col cols="12" md="3"><h4>Judul</h4></v-col>
            <v-col cols="12" md="9" v-if="!edt">
              <h4>{{ selectedNews.judul }}</h4>
            </v-col>
            <v-col cols="12" md="9" v-else>
              <h4>
                <v-text-field
                  label="judul"
                  solo
                  required
                  hide-details
                  v-model="selectedNews.judul"
                ></v-text-field>
              </h4>
            </v-col>
          </v-row>
          <v-row v-if="selectedNews.link">
            <v-col cols="12" md="3"><h4>Link</h4></v-col>
            <v-col cols="12" md="9" v-if="!edt"
              ><a
                :href="selectedNews.link"
                target="_blank"
                rel="noopener noreferrer"
                class="text-justify"
                >{{ selectedNews.link }}</a
              >
            </v-col>
            <v-col cols="12" md="9" v-else>
              <h4>
                <v-text-field
                  label="link"
                  solo
                  required
                  hide-details
                  v-model="selectedNews.link"
                ></v-text-field>
              </h4>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" md="3"><h4>Isi Berita</h4></v-col>
            <v-col cols="12" md="9" v-if="!edt">
              <p v-html="selectedNews.isi_berita"></p>
            </v-col>
            <v-col cols="12" md="9" v-else>
              <vue-editor
                placeholder="Isi berita"
                v-model="selectedNews.isi_berita"
                :editorToolbar="customToolbar"
              ></vue-editor>
            </v-col>
          </v-row>
          <v-row v-if="edt">
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="9">
              <v-btn color="indigo" dark @click="updateNews(selectedNews)"
                >simpan</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-btn color="red" text @click="delNews(selectedNews)"
            ><v-icon>mdi-delete</v-icon> hapus</v-btn
          >
          <v-btn color="cyan" v-if="!edt" text @click="edt = true"
            ><v-icon>mdi-pencil</v-icon>Edit</v-btn
          >
          <v-btn color="cyan" v-if="edt" text @click="edt = false"
            ><v-icon>mdi-close</v-icon>cancel</v-btn
          >
        </v-card-actions>
      </v-card>
      <DialogLoading v-bind:loading="loader" />
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import DialogLoading from "../../_base/dialogLoading";
import { DEL_NEWS, UPDATE_BERITA } from "../../../graphql/Module";
import { VueEditor } from "vue2-editor";

export default {
  name: "viewNews",

  components: {
    DialogLoading,
    VueEditor
  },

  computed: {},
  props: ["dialogNews", "selectedNews", "modul_id"],
  data: () => ({
    loader: false,
    edt: false,
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      [{ color: [] }]
    ]
  }),
  methods: {
    updateNews(item) {
      this.loader = true;
      const id = parseInt(item.id);
      const modul_id = this.modul_id;
      const judul = this.selectedNews.judul;
      const link = this.selectedNews.link;
      const isi_berita = this.selectedNews.isi_berita;
      this.$apollo
        .mutate({
          mutation: UPDATE_BERITA,
          variables: {
            id: id,
            modul_id: modul_id,
            judul: judul,
            link: link,
            isi_berita: isi_berita
          }
        })
        .then(() => {
          this.loader = false;
          this.edt = false;
          this.$emit("false");
          this.$emit("refetch");
          Swal.fire({
            icon: "success",
            title: "Berita telah terupdate",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    delNews(item) {
      Swal.fire({
        title: "Yakin hapus Informasi pendukung?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          const id = parseInt(item.id);
          this.loader = true;
          this.$apollo
            .mutate({
              mutation: DEL_NEWS,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.loader = false;
              this.edt = false;
              this.$emit("refetch");
              this.$emit("false");
              Swal.fire({
                icon: "success",
                title: "Berita telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },
    close() {
      this.$emit("false");
      this.files, (this.chart = null);
      this.kasus = "";
      this.edt = false;
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
