<template>
  <v-row justify="start">
    <v-dialog v-model="dialogCase" persistent scrollable max-width="600px">
      <v-card>
        <v-toolbar color="indigo">
          <span class="headline white--text">Data Kasus</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row v-if="type == 'INDIVIDUKELOMPOK'">
              <v-col cols="12" md="3"><h4>pilih fase</h4></v-col>
              <v-col cols="12" md="9">
                <v-select
                  :items="typeModule"
                  v-model="fase"
                  item-text="state"
                  item-value="abbr"
                  solo
                  required
                  label="pilih fase..."
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <h4 v-if="type == 'INDIVIDU' || fase == 'INDIVIDU'">Kasus Individu</h4>
                <h4 v-else>Kasus Kelompok</h4>
              </v-col>
              <v-col cols="12" md="9">
                <v-text-field
                  label="Nama kasus"
                  :error-messages="kasusIndErr"
                  solo
                  required
                  :counter="50"
                  v-model="kasus"
                  @input="$v.kasus.$touch()"
                  @blur="$v.kasus.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <h4>Deskripsi Kasus</h4>
              </v-col>
              <v-col cols="12" md="9" class="mb-3">
                <vue-editor
                  v-model="narasi"
                  :editorToolbar="customToolbar"
                  useCustomImageHandler
                ></vue-editor>
              </v-col>
            </v-row>
            <v-row class="mt-n5">
              <v-col cols="12" md="3">
                <h4>Tipe Data</h4>
              </v-col>
              <v-col cols="12" md="9">
                <v-select
                  :items="selectChart"
                  :error-messages="chartErr"
                  v-model="chart"
                  item-text="state"
                  item-value="abbr"
                  solo
                  required
                  label="Pilih tipe chart..."
                  @change="$v.chart.$touch()"
                  @blur="$v.chart.$touch()"
                ></v-select>
                <div>
                  <div v-if="chart === 'line'">
                    <a
                      :href="`${env}/storage/format_kasus/contoh1_line.xlsx`"
                      target="_blank"
                      class="textdecoration"
                    >
                      <v-alert
                        color="green"
                        border="left"
                        elevation="2"
                        colored-border
                        icon="mdi-file-excel"
                      >
                        <span class="d-flex justify-space-between">
                          Template line chart
                          <v-icon color="green"> mdi-download-circle </v-icon>
                        </span>
                      </v-alert>
                    </a>
                  </div>
                  <div v-if="chart === 'bar'">
                    <a
                      :href="`${env}/storage/format_kasus/contoh1_bar.xlsx`"
                      target="_blank"
                      class="textdecoration"
                    >
                      <v-alert
                        color="green"
                        border="left"
                        elevation="2"
                        colored-border
                        icon="mdi-file-excel"
                      >
                        <span class="d-flex justify-space-between">
                          Template bar chart
                          <v-icon color="green"> mdi-download-circle </v-icon>
                        </span>
                      </v-alert>
                    </a>
                  </div>
                  <div v-if="chart === 'text'">
                    <a
                      :href="`${env}/storage/format_kasus/contoh1_text.xlsx`"
                      target="_blank"
                      class="textdecoration"
                    >
                      <v-alert
                        color="green"
                        border="left"
                        elevation="2"
                        colored-border
                        icon="mdi-file-excel"
                      >
                        <span class="d-flex justify-space-between">
                          Template text
                          <v-icon color="green"> mdi-download-circle </v-icon>
                        </span>
                      </v-alert>
                    </a>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-n5">
              <v-col cols="12" md="3">
                <h4>Unggah file</h4>
              </v-col>
              <v-col cols="12" md="9">
                <v-file-input
                  v-model="files"
                  color="deep-purple accent-4"
                  :error-messages="filesErr"
                  counter
                  label="Dokumen input"
                  placeholder="Pilih dokumen data kasus"
                  hide-details="auto"
                  solo
                  v-if="chart"
                  :show-size="1000"
                  @input="$v.files.$touch()"
                  @blur="$v.files.$touch()"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                    >
                      +{{ files.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Keluar </v-btn>
          <v-btn color="indigo" dark v-if="loader"> Loading... </v-btn>
          <v-btn color="indigo" dark @click="createDataCase" v-else>
            Tambah
          </v-btn>
        </v-card-actions>
      </v-card>
      <DialogLoading v-bind:loading="loader" />
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { CREATE_DATACASE } from "../../../graphql/Module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DialogLoading from "../../_base/dialogLoading";
import { VueEditor } from "vue2-editor";

export default {
  name: "addCase",
  mixins: [validationMixin],
  validations: {
    kasus: { required },
    files: { required },
    chart: { required }
  },
  components: {
    DialogLoading,
    VueEditor
  },

  computed: {
    chartErr() {
      const errors = [];
      if (!this.$v.chart.$dirty) return errors;
      !this.$v.chart.required && errors.push("chart harus di pilih!");
      return errors;
    },
    kasusIndErr() {
      const errors = [];
      if (!this.$v.kasus.$dirty) return errors;
      !this.$v.kasus.required && errors.push("kasus tidak boleh kosong!");
      return errors;
    },
    filesErr() {
      const errors = [];
      if (!this.$v.files.$dirty) return errors;
      !this.$v.files.required && errors.push("dokumen tidak boleh kosong!");
      return errors;
    }
  },
  props: ["dialogCase", "type", "route"],
  data: () => ({
    namaPelatihan: "",
    env: process.env.VUE_APP_GRAPHQL,
    dataCaseId: null,
    chartType: "",
    dialogChart: false,
    narasi: "",
    files: null,
    kasus: "",
    fase: "",
    chart: null,
    loader: false,
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      [{ color: [] }]
    ],
    selectChart: [
      { state: "Line chart", abbr: "line" },
      { state: "Bar chart", abbr: "bar" },
      { state: "text", abbr: "text" }
    ],
    typeModule: [
      { state: "Individu", abbr: "INDIVIDU" },
      { state: "Kelompok", abbr: "KELOMPOK" }
    ]
  }),
  methods: {
    createDataCase() {
      this.$v.files.$touch();
      this.$v.chart.$touch();
      this.$v.kasus.$touch();
      if (
        !this.$v.files.required &&
        !this.$v.chart.required &&
        !this.$v.kasus.required
      ) {
        return;
      } else {
        this.loader = true;
        const modul_id = parseInt(this.route);
        const fase = this.type == "INDIVIDUKELOMPOK" ? this.fase : this.type;
        const file = this.files;
        const kasus = this.kasus;
        const chart = this.chart;
        this.$apollo
          .mutate({
            mutation: CREATE_DATACASE,
            variables: {
              modul_id: modul_id,
              fase: fase,
              file: file,
              narasi: this.narasi,
              kasus: kasus,
              chart: chart
            },
            context: {
              hasUpload: true
            }
          })
          .then(() => {
            this.kasus = "";
            this.narasi = "";
            this.files = null;
            this.chart = null;
            this.$v.files.$reset();
            this.$v.kasus.$reset();
            this.$v.chart.$reset();
            this.loader = false;
            this.$emit("false");
            Swal.fire({
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              position: "top",
              toast: true
            });
          })
          .catch(err => {
            console.log(err.graphQLErrors);
            console.log(err);
            this.loader = false;
            Swal.fire({
                icon: "error",
                title: "Dokumen harus berbentuk excel",
                showConfirmButton: true,
                confirmButtonColor: "#3F51B5",
                confirmButtonText: "Ya",
                timer: 3000,
                position: "top",
                toast: true,
              });
            console.log("hai kamu");
          });
      }
    },
    close() {
      this.$emit("false");
      this.files, (this.chart = null);
      this.kasus = "";
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
